import { RenderAnchorTag } from '../types';

/**
 * Dafault link renderer for top nav.
 */
export const renderAnchorTag: RenderAnchorTag = link => {
    return {
        as: 'a',
        href: link,
    };
};
