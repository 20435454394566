import {
    ButtonProps,
    NavigationListGroupList,
    pxToRem,
    ShorthandCollection,
    ShorthandValue,
    MegaMenuProps as AriaMegaMenuProps,
    TextWeight,
    BreadcrumbNavigationList,
    labelClassName,
    captionClassName,
    ObjectShorthandValue,
} from '@archipro-design/aria';
import { ButtonSizes } from '@archipro-design/aria';
import { useDebounceFn, useLatest, usePrevious, useUpdateEffect } from 'ahooks';

import {
    SyntheticEvent,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import {
    Directory,
    MegaMenuConfiguration,
    MegaMenuProps,
    NavigationItem,
    RenderAnchorTag,
    User,
    SubNavMenuProps,
    ActiveMegaMenuStateProp,
    DirectoryItemTile,
} from '../types';
import type { Location } from 'history';
import {
    Tracker,
    TrackingEvent,
    useTracker,
    AdvertCreative,
    useAdEventHandler,
    AdEventHandlerOnClick,
    useLogImpression,
} from '@archipro-website/tracker';

const DEFAULT_MAX_DISPLAY_LIST = 3;
const MAX_LIST_ITEM = 12;
const DEFAULT_LIST_WIDTH = pxToRem(300);

export interface MegaMenu {
    active: boolean;
    menu?: ObjectShorthandValue<AriaMegaMenuProps>;
}

export type MegaMenuState = 'collapsed' | 'expanded';

export const DEFAULT_SHOP_LABEL = 'Shop';

// Temporary workaround for issue microsoft/TypeScript#47663
// Solution found at https://github.com/microsoft/TypeScript/issues/47663#issuecomment-1270716220
import type {} from 'lodash';

export function useMegaMenu(
    location: Location,
    user: User,
    renderAnchorTag: RenderAnchorTag,
    {
        data,
        getFeaturedTile: getFeaturedTileProp,
        getAdFeaturedTile: getAdFeaturedTileProp,
        onInitFetch: onInitFetchProp,
        onHover: onHoverProp,
        disabled,
        loading,
        defaultData,
        getAdForCurrentPath,
    }: MegaMenuProps,
    defaultFullLogo: boolean,
    subnavProps: SubNavMenuProps
) {
    const featureRef = useRef(null);
    const subNavigationItems = subnavProps?.data ?? [];
    const [directory, setDirectory] = useState<Directory | null>(null);
    const [activeCategory, setActiveCategory] = useState<string | null>(null);
    const [megaMenu, setMegaMenu] = useState<MegaMenu>({ active: false });
    const prevMegaMenuState = usePrevious(megaMenu.active);
    const menusRef = useRef<MegaMenuProps['defaultData']>(
        defaultData ?? ({} as MegaMenuProps['defaultData'])
    );
    const directoryRef = useLatest<Directory | null>(directory);
    const getFeaturedTile = useLatest(getFeaturedTileProp);
    const getAdFeaturedTile = useLatest(getAdFeaturedTileProp);
    const onInitFetch = useLatest(onInitFetchProp);
    const onHover = useLatest(onHoverProp);
    const onSubNavItemHover = useLatest(subnavProps?.onHover);
    const tracker = useTracker();
    const adsForPathRef = useRef<Record<string, DirectoryItemTile | null>>({});

    disabled = false;

    let ad: AdvertCreative | null = null;
    let featuredTile: DirectoryItemTile | null = null;
    if (directory !== null && menusRef.current) {
        featuredTile = menusRef.current[directory]?.featuredTile;
        if (featuredTile && featuredTile.ad) {
            ad = featuredTile.ad;
        }
    }

    const { onClick } = useAdEventHandler(ad, featureRef, {
        impressByVersion: true,
        version: featuredTile?.ID,
        disableServeEvent: true,
    });
    useLogImpression({
        data:
            ad === null && featuredTile
                ? {
                      type: 'MegaMenu_Featured',
                      itemID: featuredTile.ID,
                      professionalID: featuredTile.professionalID || 0,
                  }
                : undefined,
        ref: featureRef,
        impressByVersion: true,
        version: featuredTile?.ID,
    });

    const isGuest = !user || user.__typename === 'Guest';
    const [megaMenuState, setMegaMenuState] = useState<
        MegaMenuState | undefined
    >(undefined);

    const handleHoverItem = useCallback(
        (navigationPath: NavigationItem[]) => {
            if (!directory || !menusRef.current?.[directory]) {
                return;
            }
            const cfg = menusRef.current[directory];
            setMegaMenu({
                active: true,
                menu: createMegaMenu(
                    tracker,
                    renderAnchorTag,
                    getFeaturedTile.current,
                    getAdFeaturedTile.current,
                    isGuest,
                    cfg,
                    handleHoverItem,
                    onClick,
                    featureRef,
                    location.pathname,
                    adsForPathRef,
                    navigationPath
                ),
            });
        },
        [
            directory,
            tracker,
            renderAnchorTag,
            getFeaturedTile,
            getAdFeaturedTile,
            isGuest,
            onClick,
        ]
    );

    useEffect(() => {
        setMegaMenu(prev => ({ ...prev, active: false }));
        setDirectory(null);
    }, [location]);

    useEffect(() => {
        // trigger only after megaMenu collapsed
        if (!prevMegaMenuState) {
            return;
        }
        setMegaMenuState('collapsed');
    }, [prevMegaMenuState]);

    // update mega menu after root directory changed
    useUpdateEffect(() => {
        if (!directory || disabled) {
            setMegaMenu(prev => ({ ...prev, active: false }));
            return;
        }

        const menu = menusRef.current?.[directory];
        if (!menu) {
            // no data, set mega menu to loading state, start fetching data
            setMegaMenu({
                active: true,
                menu: createLoadingMegaMenu(),
            });

            onInitFetch.current(directory);
            !defaultFullLogo && setMegaMenuState('expanded');
            return;
        }

        onHover.current?.(directory);

        if (activeCategory) {
            onSubNavItemHover.current?.({
                directory,
                category: activeCategory,
            });
        }

        // already fetched this directory data, update mega menu
        const selectedItems = preselectItemByCategory(menu, activeCategory);
        setMegaMenu({
            active: true,
            menu: createMegaMenu(
                tracker,
                renderAnchorTag,
                getFeaturedTile.current,
                getAdFeaturedTile.current,
                isGuest,
                menu,
                handleHoverItem,
                onClick,
                featureRef,
                location.pathname,
                adsForPathRef,
                selectedItems
            ),
        });
        !defaultFullLogo && setMegaMenuState('expanded');
    }, [
        disabled,
        directory,
        handleHoverItem,
        isGuest,
        getFeaturedTile,
        getAdFeaturedTile,
        onInitFetch,
        renderAnchorTag,
        tracker,
        activeCategory,
        onClick,
        featureRef,
    ]);

    // update menu map data after fetch data success
    useEffect(() => {
        if (loading || disabled) {
            return;
        }

        if (!data) {
            setMegaMenu(prev => ({ ...prev, active: false })); // close mega menu
            return;
        }

        const menu = data;
        if (menusRef.current) {
            menusRef.current[menu.directory] = menu;
        }

        if (menu.directory !== directoryRef.current) {
            return;
        }

        const selectedItems = preselectItemByCategory(menu, activeCategory);
        setMegaMenu({
            active: true,
            menu: createMegaMenu(
                tracker,
                renderAnchorTag,
                getFeaturedTile.current,
                getAdFeaturedTile.current,
                isGuest,
                menu,
                handleHoverItem,
                onClick,
                featureRef,
                location.pathname,
                adsForPathRef,
                selectedItems
            ),
        });
    }, [
        disabled,
        data,
        directoryRef,
        getFeaturedTile,
        handleHoverItem,
        isGuest,
        renderAnchorTag,
        tracker,
        loading,
        activeCategory,
        onClick,
        getAdFeaturedTile,
    ]);

    // Query and cache the ad for the current path if any.
    // We can set up different sponsorships for different categories.
    // But we load the feature tile data at the beginning and would not load it again unless refreshing the page.
    // So we need to client-side query the ad for the current path.
    useEffect(() => {
        const adInCache =
            adsForPathRef.current[location.pathname + '|' + location.search];
        if (!getAdForCurrentPath || adInCache || adInCache === null) {
            return;
        }

        const fetchAd = async (pathname: string) => {
            const ad = await getAdForCurrentPath();
            if (ad || ad === null) {
                adsForPathRef.current[pathname] = ad;
            }
        };
        fetchAd(location.pathname)
            .then()
            .catch(() => {
                // catch the error to avoid the error thrown by the promise.
            });
    }, [location.pathname, location.search]);

    const handleChangeMinorNavigationItem = (
        _: React.SyntheticEvent<HTMLElement, Event>,
        directory?: Directory
    ) => {
        if (disabled) {
            return;
        }
        setDirectory(directory ?? null);
    };

    const { run: onMouseOver, cancel: onMouseLeave } = useDebounceFn(
        handleChangeMinorNavigationItem,
        { wait: 200 }
    );

    const handleChangeSubNavigationItem = (
        _: React.SyntheticEvent<HTMLElement, Event>,
        args?: ActiveMegaMenuStateProp
    ) => {
        if (disabled) {
            return;
        }
        setDirectory(args?.directory ?? null);
        setActiveCategory(args?.category ?? null);
    };

    const { run: onSubNavItemMouseOver, cancel: onSubNavItemMouseLeave } =
        useDebounceFn(handleChangeSubNavigationItem, { wait: 200 });

    return {
        megaMenu,
        subNavigationItems,
        directory,
        setDirectory,
        handleChangeMinorNavigationItem,
        handleChangeSubNavigationItem,
        onMouseOver,
        onMouseLeave,
        megaMenuState,
        setMegaMenuState,
        onSubNavItemMouseOver,
        onSubNavItemMouseLeave,
    };
}

function createMegaMenu(
    tracker: Tracker,
    renderAnchorTag: RenderAnchorTag,
    getFeaturedTile: MegaMenuProps['getFeaturedTile'],
    getAdFeaturedTile: MegaMenuProps['getAdFeaturedTile'],
    isGuest: boolean,
    cfg: MegaMenuConfiguration,
    onHoverItem: (navigationPath: NavigationItem[]) => void,
    onClick: AdEventHandlerOnClick,
    featureRef: React.RefObject<HTMLDivElement>,
    pathname: string,
    adsForPathRef: React.RefObject<Record<string, DirectoryItemTile | null>>,
    selectedItems?: NavigationItem[]
): AriaMegaMenuProps {
    const breadcrumbNavigation = createBreadcrumbNavigation(
        tracker,
        renderAnchorTag,
        onHoverItem,
        cfg.directoryRootNavigation,
        selectedItems
    );

    const megaMenuProps: AriaMegaMenuProps = {
        leftNavigationGroup: {
            lists: cfg.leftNavigationGroup.map(list =>
                createMegaMenuLeftNavList(
                    tracker,
                    renderAnchorTag,
                    list,
                    isGuest
                )
            ),
        },
        breadcrumbNavigation,
    };

    const adForCurrentPath = adsForPathRef.current?.[pathname];
    if (adForCurrentPath && adForCurrentPath.type === cfg.directory) {
        megaMenuProps.featuredTileOverride = getAdFeaturedTile(
            adForCurrentPath,
            onClick,
            featureRef
        );
    } else if (cfg.featuredTile?.ad) {
        megaMenuProps.featuredTileOverride = getAdFeaturedTile(
            cfg.featuredTile,
            onClick,
            featureRef
        );
    } else {
        const onClickFeaturedTile = () => {
            tracker.log('MMTileClick', {
                url: new URL(window.location.href),
                targetTracker: 'archiproTrackerV2',
                data: {
                    ExtraData: {
                        itemID: cfg.featuredTile?.ID,
                    },
                },
            });
        };
        megaMenuProps.featuredTile =
            cfg.featuredTile &&
            getFeaturedTile(cfg.featuredTile, onClickFeaturedTile, featureRef);
    }

    return megaMenuProps;
}

export const BREADCRUMB_LIST_WIDTHS = [
    pxToRem(270),
    pxToRem(270),
    pxToRem(270),
];

function createLoadingMegaMenu(): AriaMegaMenuProps {
    const widths = BREADCRUMB_LIST_WIDTHS;
    const list: NavigationListGroupList = {
        kind: 'loadingList',
        list: { variables: { listWidth: DEFAULT_LIST_WIDTH } },
    };
    return {
        leftNavigationGroup: {
            lists: Array.from({ length: 3 }).map(() => list),
        },
        breadcrumbNavigation: {
            lists: widths.map((listWidth, index) => {
                const variables = { listWidth };
                const showSkeletonMoreButton = index === 0;
                const size = index ? 'medium' : 'large';
                const list = { variables, size, showSkeletonMoreButton };
                return { kind: 'loadingList', list };
            }),
        },
        featuredTile: { tile: { type: 'skeleton', instanceProps: {} } },
    };
}

function getLeftNavItemClickCallback(
    tracker: Tracker,
    item: NavigationItem,
    child: NavigationItem,
    childIndex: number
) {
    let event: TrackingEvent | undefined = undefined;
    let extraData: string | undefined = undefined;
    if (item.id === 'Featured') {
        event = 'MMFeaturedClick';
        extraData = child.name;
    } else if (item.id === 'Explore') {
        event = 'MMExploreClick';
        extraData = `${child.id}-${childIndex + 1}`;
    } else if (item.id === 'Design Boards') {
        if (child.id === 'View Saved Products') {
            event = 'MMSavedItems';
        }
    }

    if (!event) {
        return undefined;
    }

    return () => {
        if (event) {
            void tracker.log(event, {
                url: new URL(window.location.href),
                targetTracker: 'archiproTracker',
                data: extraData
                    ? {
                          ExtraData: extraData,
                      }
                    : undefined,
            });
        }
    };
}

function createMegaMenuLeftNavList(
    tracker: Tracker,
    renderAnchorTag: RenderAnchorTag,
    item: NavigationItem,
    isGuest: boolean,
    listWidth = DEFAULT_LIST_WIDTH
): NavigationListGroupList {
    const items: ShorthandCollection<ButtonProps> = [];
    item.children?.forEach((child, index) => {
        if (!isGuest || !child.hideForGuest) {
            items.push(
                createMegaMenuNavListItem({
                    renderAnchorTag,
                    name: child.name,
                    href: child.link,
                    weight: 'regular',
                    onClick: getLeftNavItemClickCallback(
                        tracker,
                        item,
                        child,
                        index
                    ),
                })
            );
        }
    });
    return {
        kind: 'list',
        list: {
            title: {
                children: item.name,
                styles: {
                    [`&.${captionClassName}`]: {
                        fontSize: pxToRem(16.5),
                        lineHeight: pxToRem(19),
                    },
                },
            },
            items,
            variables: {
                listWidth,
                titleOptionGap: pxToRem(12),
                titleMinHeight: 'unset',
            },
        },
    };
}
function createBreadcrumbNavigation(
    tracker: Tracker,
    renderAnchorTag: RenderAnchorTag,
    onHoverItem: (navigationPath: NavigationItem[]) => void,
    rootNavigation?: NavigationItem,
    selectedItems?: NavigationItem[]
): AriaMegaMenuProps['breadcrumbNavigation'] {
    if (!rootNavigation) return null;
    const listWidths = BREADCRUMB_LIST_WIDTHS;
    const items: NavigationItem[] = selectedItems ?? [rootNavigation];
    const lists = Array.from({ length: DEFAULT_MAX_DISPLAY_LIST }).map(
        (_, index) => {
            const item = items[index];
            const navigationPath = items.slice(0, index + 1);
            const listWidth =
                listWidths[Math.min(index, Math.max(listWidths.length - 1, 0))];
            return createBreadcrumbList({
                tracker,
                renderAnchorTag,
                navigationPath,
                item,
                // last column nav items don't update mega menu
                onHoverItem:
                    index + 1 < DEFAULT_MAX_DISPLAY_LIST
                        ? onHoverItem
                        : undefined,
                selectedChild: items[index + 1],
                listWidth,
                defaultItemSize: 16,
                isRoot: index === 0,
            });
        }
    );
    return { lists };
}

interface CreateBreadcrumbListProps {
    tracker: Tracker;
    renderAnchorTag: RenderAnchorTag;
    navigationPath: NavigationItem[];
    item?: NavigationItem;
    onHoverItem?: (navigationPath: NavigationItem[]) => void;
    selectedChild?: NavigationItem;
    listWidth?: string;
    defaultItemSize?: ButtonSizes;
    isRoot?: boolean;
}

function createBreadcrumbList({
    tracker,
    renderAnchorTag,
    navigationPath,
    item,
    onHoverItem,
    selectedChild,
    listWidth = 'unset',
    defaultItemSize = 16,
}: CreateBreadcrumbListProps): BreadcrumbNavigationList {
    const items = item?.children
        ?.map(n => {
            const isSelected = selectedChild?.id === n.id;
            return {
                ...(createMegaMenuNavListItem({
                    renderAnchorTag,
                    name: n.name,
                    href: n.link,
                    weight: isSelected ? 'medium' : 'regular',
                }) as ButtonProps),
                selected: isSelected,
                onMouseOver: () => onHoverItem?.([...navigationPath, n]),
                onFocus: () => onHoverItem?.([...navigationPath, n]),
                onClick: () => {
                    void tracker.log('MMCategoryClick', {
                        url: new URL(window.location.href),
                        targetTracker: 'archiproTracker',
                        data: {
                            ExtraData: item.id,
                        },
                    });
                },
                styles: {
                    [`& .${labelClassName}`]: {
                        lineHeight: pxToRem(18),
                        marginBottom: pxToRem(3),
                    },
                },
            };
        })
        .slice(0, MAX_LIST_ITEM);
    const empty = !item || !items || !items.length;
    const onViewAllClick = () => {
        void tracker.log('MMViewAllClick', {
            url: new URL(window.location.href),
            targetTracker: 'archiproTracker',
            data: {
                ExtraData: item?.id ?? '',
            },
        });
    };
    return {
        kind: 'list',
        list: {
            title: {
                children: empty ? '' : item?.name,
                uc: true,
                styles: {
                    [`&.${captionClassName}`]: {
                        fontSize: pxToRem(16),
                        lineHeight: pxToRem(19),
                    },
                },
            },
            items: items,
            moreButton: empty
                ? null
                : createMegaMenuNavListItem({
                      renderAnchorTag,
                      name: 'View all',
                      href: item.link,
                      weight: 'medium',
                      onClick: onViewAllClick,
                      size: 16,
                  }),
            // pixel match....
            variables: {
                listWidth: `calc(${listWidth} - 1px)`,
                titleMinHeight: 'unset',
            },
            defaultItemSize,
        },
    };
}

interface CreateMegaMenuNavListItemProps extends ButtonProps {
    renderAnchorTag: RenderAnchorTag;
    name: string;
    href?: string;
    weight: TextWeight;
    onClick?: (e: SyntheticEvent) => void;
}

function createMegaMenuNavListItem({
    renderAnchorTag,
    name,
    href,
    weight = 'regular',
    onClick,
    ...rest
}: CreateMegaMenuNavListItemProps): ShorthandValue<ButtonProps> {
    return {
        ...rest,
        children: { children: name, weight },
        ...(href && renderAnchorTag(href)),
        onClick: onClick,
        styles: {
            [`& .${labelClassName}`]: {
                lineHeight: 1.3,
            },
        },
        size: 16,
    } as ButtonProps;
}

function preselectItemByCategory(
    menu: MegaMenuConfiguration | undefined,
    activeCategory: string | null
) {
    if (!activeCategory || !menu?.directoryRootNavigation) return undefined;

    const selectItem = menu.directoryRootNavigation?.children?.find(
        c => c.link === activeCategory
    );
    return menu.directoryRootNavigation && selectItem
        ? [menu.directoryRootNavigation, selectItem]
        : undefined;
}
