import { Location } from 'history';
import { Directory } from '../types';

interface SearchCategory {
    INDEX: string;
    TITLE: string;
    ESINDEX: string;
    ESKEY: string;
    SEARCH_URL?: string;
    SITE_URL: string;
    DETAIL_URL?: string;
    DIRECTORY?: Directory;
    ITEM_TYPE: string;
}

export type SEARCH_CATEGORY_KEY = Directory | 'all';

export const SEARCH_CATEGORIES: Record<SEARCH_CATEGORY_KEY, SearchCategory> = {
    all: {
        INDEX: 'all',
        TITLE: 'All',
        ESINDEX: '',
        ESKEY: '',
        SEARCH_URL: '',
        SITE_URL: '',
        ITEM_TYPE: 'result',
    },
    project: {
        INDEX: 'prod_projects',
        TITLE: 'Projects',
        ESINDEX: 'project',
        ESKEY: 'Project',
        SEARCH_URL: '/category_projects',
        SITE_URL: '/projects',
        DETAIL_URL: '/project',
        DIRECTORY: 'project',
        ITEM_TYPE: 'project',
    },
    product: {
        INDEX: 'prod_products',
        TITLE: 'Products',
        ESINDEX: 'product',
        ESKEY: 'Product',
        SEARCH_URL: '/category_products',
        SITE_URL: '/products',
        DETAIL_URL: '/product',
        DIRECTORY: 'product',
        ITEM_TYPE: 'product',
    },
    professional: {
        INDEX: 'prod_professionals',
        TITLE: 'Professionals',
        ESINDEX: 'professional',
        ESKEY: 'Professional',
        SEARCH_URL: '/category_professionals',
        SITE_URL: '/professionals',
        DETAIL_URL: '/professional',
        DIRECTORY: 'professional',
        ITEM_TYPE: 'professional',
    },
    article: {
        INDEX: 'prod_blogposts',
        TITLE: 'Articles',
        ESINDEX: 'blogpost',
        ESKEY: 'Blog',
        SEARCH_URL: '/category_articles',
        SITE_URL: '/articles',
        DETAIL_URL: '/article',
        DIRECTORY: 'article',
        ITEM_TYPE: 'article',
    },
};

export function getCategoryFromUrl(location: Location): SearchCategory {
    let category = SEARCH_CATEGORIES.all;
    if (!location.pathname) return category;

    const tmpArr = /\/category_([\w-]+)\/?/g.exec(location.pathname);
    const categoryUrl =
        tmpArr && tmpArr[0]
            ? tmpArr[0]
            : location.pathname.replace('/search', '');
    if (!categoryUrl) return category;

    const keys = Object.keys(SEARCH_CATEGORIES) as SEARCH_CATEGORY_KEY[];

    keys.forEach(k => {
        if (k === 'all') {
            return;
        }

        const { SITE_URL, SEARCH_URL, DETAIL_URL } = SEARCH_CATEGORIES[k];

        if (
            categoryUrl.startsWith(SITE_URL) ||
            (SEARCH_URL && categoryUrl.startsWith(SEARCH_URL)) ||
            (DETAIL_URL && categoryUrl.startsWith(DETAIL_URL))
        ) {
            category = SEARCH_CATEGORIES[k];
        }
    });

    return category;
}
