import {
    MutableRefObject,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import {
    ButtonProps,
    CategoryTreeProps,
    Label,
    pxArrayToRem,
    pxToRem,
} from '@archipro-design/aria';
import { AngleRight } from '@archipro-design/icons';
import {
    Category,
    Directory,
    FetchCategoriesResult,
    RenderAnchorTag,
} from '../types';
import { useLatest, useUpdateEffect } from 'ahooks';
import { SEARCH_CATEGORIES } from '../utils/getCategoryFromUrl';

import { getDirectoryFromLink } from '../utils/getDirectoryFromLink';
import { renderAnchorTag } from '../utils/renderAnchorTag';

const NAV_ITEMS: {
    title: string;
    directory: Directory;
    itemName: string;
}[] = [
    { title: 'Products', directory: 'product', itemName: 'Products' },
    { title: 'Projects', directory: 'project', itemName: 'Projects' },
    { title: 'Pros', directory: 'professional', itemName: 'Professionals' },
    { title: 'Archizeen', directory: 'article', itemName: 'Articles' },
];

interface CategoryMap {
    [key: string]: { [link: string]: Category };
}

const INITIAL_ROOT_CATEGORIES: CategoryMap = NAV_ITEMS.reduce(
    (categories, item) => {
        const link = SEARCH_CATEGORIES[item.directory].SITE_URL;
        const { directory: id, itemName: name } = item;
        categories[id] = {
            [link]: { id, name, link, itemCount: 0, childrenCount: 5 },
        };
        return categories;
    },
    {} as CategoryMap
);

export interface UseCategoryTreeOpts {
    active: boolean;
    onNavigate: (to: string, navigationType: 'push' | 'replace') => void;
    data?: FetchCategoriesResult;
    onFetchCategoryTree: (directory: Directory, link: string) => void;
    activeCategoryLink?: string;
    onActiveCategoryLinkChange: (val?: string) => void;
    renderAnchorTag: RenderAnchorTag;
    appType?: 'web' | 'manager';
}

interface UseCategoryTreeResult {
    categoryTree: CategoryTreeProps | null;
}

export const useCategoryTree = ({
    active,
    onNavigate,
    data,
    onFetchCategoryTree,
    activeCategoryLink,
    onActiveCategoryLinkChange,
    appType,
}: UseCategoryTreeOpts): UseCategoryTreeResult => {
    const onNavigateRef = useLatest(onNavigate);
    const listRef = useRef<HTMLDivElement>(null);

    const onFetchCategory = useLatest(onFetchCategoryTree);
    const urlDirectory = getDirectoryFromLink(activeCategoryLink);
    const categoryTreesRef = useRef<CategoryMap>(INITIAL_ROOT_CATEGORIES);

    const handleChangeCategory = useCallback(
        (link?: string) => {
            onActiveCategoryLinkChange(link);
        },
        [onActiveCategoryLinkChange]
    );

    const [categoryTreeProps, setCategoryTreeProps] =
        useState<CategoryTreeProps | null>(null);

    const handleNavigate = useCallback(
        (link: string) => onNavigateRef.current(link, 'push'),
        [onNavigateRef]
    );

    useEffect(() => {
        if (!active) {
            setCategoryTreeProps(null);
            return;
        }

        const link = urlDirectory ? activeCategoryLink : null;
        const category =
            link && urlDirectory
                ? categoryTreesRef.current[urlDirectory]?.[link]
                : undefined;

        if (urlDirectory && link && !category?.children) {
            onFetchCategory.current(urlDirectory, link);
        }

        setCategoryTreeProps(
            createCategoryTreeProps(
                handleChangeCategory,
                handleNavigate,
                listRef,
                urlDirectory,
                renderAnchorTag,
                appType,
                category,
                onFetchCategory
            )
        );
    }, [
        active,
        urlDirectory,
        activeCategoryLink,
        onFetchCategory,
        handleChangeCategory,
        handleNavigate,
    ]);

    useUpdateEffect(() => {
        if (!active || !data) return;
        const { category, directory } = data;
        categoryTreesRef.current[directory] ||= {};
        const link = category.link ?? category.parentLink;
        categoryTreesRef.current[directory][link] = category;
        category.children?.forEach(child => {
            child.parentLink = category.link;
            categoryTreesRef.current[directory][child.link] = child;
        });

        setCategoryTreeProps(
            createCategoryTreeProps(
                handleChangeCategory,
                handleNavigate,
                listRef,
                directory,
                renderAnchorTag,
                appType,
                category,
                onFetchCategory
            )
        );
    }, [data]);

    return { categoryTree: categoryTreeProps };
};

const createCategoryTreeProps = (
    onChangeCategory: (link?: string) => void,
    onNavigate: (link: string) => void,
    listRef: React.RefObject<HTMLDivElement>,
    directory: Directory | null,
    renderAnchorTag: RenderAnchorTag,
    appType?: 'web' | 'manager',
    category?: Category,
    onFetchCategory?: MutableRefObject<
        (directory: Directory, link: string) => void
    >
): CategoryTreeProps => {
    const items: ButtonProps[] = [];
    if (category) {
        const categoryProps =
            appType === 'manager'
                ? {
                      ...renderAnchorTag(category?.link ?? ''),
                  }
                : {};
        items.push({
            children: <Label weight={'medium'}>View all</Label>,
            ...categoryProps,
            onClick: () => appType !== 'manager' && onNavigate(category?.link),
        });
        if (category.children) {
            category.children.forEach(c => {
                const props =
                    appType === 'manager'
                        ? {
                              ...renderAnchorTag(c.link ?? ''),
                          }
                        : {};
                items.push({
                    children: c.name,
                    key: c.name,
                    iconAfter: c.childrenCount ? <AngleRight /> : undefined,
                    ...props,
                    onClick: e => {
                        if (c.childrenCount) {
                            e.preventDefault();
                            // fetch as soon as you click
                            if (directory) {
                                onFetchCategory?.current(directory, c.link);
                            }
                            onChangeCategory(c.link);
                            return;
                        }

                        appType !== 'manager' && onNavigate(c.link);
                    },
                });
            });
        } else if (category.childrenCount) {
            {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                [...Array(category.childrenCount)].map((_, i) => {
                    items.push({
                        key: `placeHolder${i}`,
                        children: {
                            children: (
                                <div
                                    style={{
                                        width: '90%',
                                        height: pxToRem(18),
                                    }}
                                />
                            ),
                        },
                    });
                });
            }
        }
    }

    return {
        ...(directory &&
            category && {
                header: {
                    title: category.name,
                    subtitle: `${category.itemCount.toLocaleString()} ${
                        NAV_ITEMS.find(i => i.directory === directory)
                            ?.itemName ?? ''
                    }`,
                    backButton: {
                        onClick: () => onChangeCategory(category.parentLink),
                    },
                    variables: {
                        contentContainerPadding: pxArrayToRem([3, 42, 0, 0]),
                        titleFontSize: pxToRem(16),
                        subtitleFontSize: pxToRem(14),
                        subtitleLineHeight: pxToRem(14),
                    },
                },
            }),

        ...(items &&
            items.length > 0 && {
                list: {
                    items,
                    ref: listRef,

                    variables: {
                        itemPadding: pxArrayToRem([16, 20]),
                    },
                },
            }),
        ...((!items || items.length === 0) && {
            list: {
                items: [],
                ref: listRef,
                styles: {
                    height: '-webkit-fill-available',
                },
            },
        }),
        variables: {
            listPadding: '0',
        },
    };
};
