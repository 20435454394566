import {
    pxToRem,
    StyleRule,
    captionClassName,
    imageClassName,
} from '@archipro-design/aria';

export const ReseneSponsor: StyleRule = () => ({
    marginTop: pxToRem(57),

    [`& img`]: {
        backgroundBlendMode: 'darken',
        mixBlendMode: 'multiply',
    },
});

export const ReseneSponsorHeader: StyleRule = () => ({
    marginBottom: pxToRem(24),
    [`&.${captionClassName}`]: {
        fontSize: pxToRem(16.5),
        lineHeight: pxToRem(19),
    },
});

export const ReseneSponsorLogo: StyleRule = () => ({
    marginLeft: pxToRem(-21),
    width: pxToRem(187),
    height: pxToRem(74),

    [`& .${imageClassName}`]: {
        width: '100%',
    },
});
