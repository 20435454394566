import {
    ComponentEventHandler,
    MegaMenuProps as AriaMegaMenuProps,
} from '@archipro-design/aria';
import {
    AnalyticsStatusEnum,
    CurrentMemberFragment,
    Guest,
    InboxContextTypeEnum,
    PermissionEnum,
} from '../../generated/graphql';
import { SupportedCountry } from '@archipro-website/localisation';
import { ElementType, RefObject } from 'react';
import {
    AdEventHandlerOnClick,
    AdvertCreative,
} from '@archipro-website/tracker';

export type TopNavRef = RefObject<HTMLDivElement>;

/**
 * NOTE:
 * Copied from packages/top-navigation/src/lib/types.ts
 * Make sure to keep in sync
 */
export type Directory = 'product' | 'project' | 'professional' | 'article';

export const ALL_DIRECTORIES: Readonly<Directory[]> = [
    'product',
    'project',
    'professional',
    'article',
];
export interface DirectoryItemTile {
    ID: number;
    type: Directory;
    professionalID?: number;
    ad?: AdvertCreative | null;
}

export { PermissionEnum };
export interface TopnavClientEnvironment extends Record<string, unknown> {
    host: string;
    version: string;
}

export interface DirectoryItem {
    key: Directory;
    label: string;
    href: string;
    count: number;
    labelPrefix?: string;
}

export type DirectoryMenuItems = Record<`${Directory}s`, DirectoryItem>;

export interface ShorthandConfig {
    as?: ElementType;
}

export type LoggedInUser = Pick<
    CurrentMemberFragment,
    | '__typename'
    | 'FirstName'
    | 'LastName'
    | 'Initials'
    | 'ProfileImage'
    | 'IsArchiproStaff'
    | 'MemberImage'
    | 'FeatureFlags'
    | 'OrderNotificationCount'
    | 'Permissions'
> & {
    Professionals: Array<{ URLSegment: string }>;
    TeamMembers: Array<{
        Professional: {
            ID: number;
            URLSegment: string;
            AnalyticsStatus: AnalyticsStatusEnum;
            Permissions: PermissionEnum[];
            Title: string;
            CompanyLogo?: string | null;
            LogoBackgroundColor?: string | null;
            Country?: SupportedCountry | null;
            Link?: string | null;
        };
    }>;
};
export type GuestUser = Pick<Guest, '__typename'>;
export type User = LoggedInUser | GuestUser;

export type RenderAnchorTag = (link: string) => ShorthandConfig;

interface Notification {
    count: number;
}

interface InboxNotification extends Notification {
    id: number;
}

interface CompanyInboxNotification extends Notification {
    id: number;
    inboxType: InboxContextTypeEnum;
}

export interface Notifications {
    designBoard: Notification;
    website: Notification;
    userInbox?: InboxNotification;
    companyInboxes?: CompanyInboxNotification[];
    cart: Notification;
}

export interface CurrencyAmount {
    Amount: number;
    ShortFormat: string;
}
export interface CartItem {
    ID: number;
    Quantity: number;
    Sample: boolean;
    Variant: {
        ID: number;
        Product: { ID: number; Title: string };
        Thumbnail?: {
            ID: string;
            Desktop?: string | null;
            Mobile?: string | null;
        } | null;
    };
    Price: { ShortFormat: string };
    RetailPrice: { ShortFormat: string };
}

export interface Cart {
    ID: number;
    LastEdited?: string | null;
    LastAddedItems: Array<CartItem>;
    ItemTotal?: CurrencyAmount | null;
    CartTotal?: CurrencyAmount | null;
}

export type TopNavTransitionState = 'static' | 'slide-in' | 'slide-out';

export interface SearchProps {
    defaultSearchValue?: string;
    data?: SearchNavResults | null;
    isActive: boolean;

    onClick: onSearchClick;
    onChange: onSearchType;
    onNavigate: onSearchNavigate;
}
export interface TopNavSearchItem {
    search: string;
    category?: string;
    categoryLink?: string;
}

export interface SearchNavResults {
    all: SearchNavResultItem[];
    blogpost: SearchNavResultItem[];
    product: SearchNavResultItem[];
    professional: SearchNavResultItem[];
    project: SearchNavResultItem[];
    suggestion: SearchNavResultItem[];
}

export interface SearchNavResultItem {
    IType: string;
    CategoryTitle: string;
    Link: string;
}

export type onSearchClick = ComponentEventHandler<TopNavSearchItem>;
export type onSearchType = ComponentEventHandler<string>;
export type onSearchNavigate = (
    to: string,
    navigationType: 'push' | 'replace'
) => void;

export interface MegaMenuProps {
    data?: MegaMenuConfiguration;
    defaultData?: Record<Directory, MegaMenuConfiguration>;
    disabled?: boolean;
    loading?: boolean;
    getFeaturedTile(
        item: DirectoryItemTile,
        onClick: () => void,
        ref: RefObject<HTMLElement>
    ): AriaMegaMenuProps['featuredTile'];
    getAdFeaturedTile(
        item: DirectoryItemTile,
        onClick: AdEventHandlerOnClick,
        ref: React.RefObject<HTMLDivElement>
    ): AriaMegaMenuProps['featuredTileOverride'];
    onInitFetch: (directory: Directory) => void;
    // Added after removing the lazy fetch from remix.
    onHover?: (directory: Directory) => void;
    // Get the ad for the current path.
    // return null if there is no ad for the current path. the null will be cached.
    // return undefined if the ad is not applicable for the current path. the undefined will not be cached.
    getAdForCurrentPath?: () => Promise<DirectoryItemTile | null | undefined>;
}

export interface ActiveMegaMenuStateProp {
    directory: Directory;
    category?: string;
}

export interface SubNavMenuProps {
    data?: MegaMenuConfiguration['subNavItems'];
    directory: Directory;
    onHover?: (args: ActiveMegaMenuStateProp) => void;
}

export interface MegaMenuConfiguration {
    leftNavigationGroup: NavigationItem[];
    directoryRootNavigation?: NavigationItem;
    directory: Directory;
    featuredTile: DirectoryItemTile | null;
    subNavItems?: NavigationItem[];
    showReseneSponsor?: boolean;
}

export interface NavigationItem {
    id: string;
    name: string;
    link: string;
    children?: NavigationItem[];
    hideForGuest?: boolean;
}

export interface Category {
    id: string;
    name: string;
    link: string;
    itemCount: number;
    childrenCount?: number;
    children?: Category[];
    parentLink?: string;
}

export interface FetchCategoriesResult {
    directory: Directory;
    category: Category;
}

export interface PathParameter {
    name: string;
    pathElement: string;
}

export type TopNavFeatureFlags = Record<
    | 'ecommerceEnabled'
    | 'shopNavEnabled'
    | 'ecommerceActiveEnabled'
    | 'searchPreFiliteringEnabled'
    | 'ba2NavEnabled',
    boolean
>;
