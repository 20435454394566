import {
    Flex,
    pxToRem,
    TopNavigationProps,
    Image,
    Caption,
    ListItemProps,
    Box,
    pxArrayToRem,
    ShorthandValue,
    BoxProps,
} from '@archipro-design/aria';
import { ChevronRight } from '@archipro-design/icons';

import { RenderAnchorTag } from '../types';

const MAJOR_NAV_ITEMS: { link: string; label: string; tagBefore?: string }[] = [
    { label: 'Search Products', link: '/products', tagBefore: 'Shop' },
    { label: 'Browse Projects', link: '/projects' },
    { label: 'Connect with Professionals', link: '/professionals' },
    { label: 'Read Archizeen', link: '/articles' },
];

const MAJOR_NAV_ITEMS_NON_SHOP: {
    link: string;
    label: string;
    tagBefore?: string;
}[] = [
    { label: 'Search Products', link: '/products' },
    { label: 'Browse Projects', link: '/projects' },
    { label: 'Connect with Professionals', link: '/professionals' },
    { label: 'Read Archizeen', link: '/articles' },
];

const MINOR_NAV_ITEMS: ListItemProps[] = [
    {
        label: 'Digital Magazines',
        link: '/digital-magazines',
        iconAfter: <ChevronRight variables={{ '1xSize': pxToRem(12) }} />,
    },
    {
        label: 'About us',
        link: '/about-us',

        iconAfter: <ChevronRight variables={{ '1xSize': pxToRem(12) }} />,
    },
    {
        label: 'Careers',
        link: 'https://apply.workable.com/archipro-3/',

        iconAfter: <ChevronRight variables={{ '1xSize': pxToRem(12) }} />,
    },
    {
        label: 'Contact',
        link: '/contact-us',
        iconAfter: <ChevronRight variables={{ '1xSize': pxToRem(12) }} />,
    },
];

const EVENTS_ITEMS: { imageSrc: string; link: string; title: string }[] = [
    {
        imageSrc: '/assets/website/images/ui/events_mge_mobile.png',
        title: 'Home Design Evening',
        link: '/home-design-event',
    },
];

export interface UseAppMenuSlotOpts {
    renderAnchorTag: RenderAnchorTag;
    onCategoryItemClick: (link: string) => void;
    showSearchMenu?: boolean;
    shopNavEnabled?: boolean;
    assetsBaseUrl: string;
    bottomNavigationAdditionalContent?: ShorthandValue<BoxProps>;
}

export function useAppMenuSlot({
    renderAnchorTag,
    onCategoryItemClick,
    showSearchMenu = false,
    shopNavEnabled,
    assetsBaseUrl,
    bottomNavigationAdditionalContent,
}: UseAppMenuSlotOpts): TopNavigationProps['appMenu'] {
    return {
        majorNavItems: {
            items: (shopNavEnabled
                ? MAJOR_NAV_ITEMS
                : MAJOR_NAV_ITEMS_NON_SHOP
            ).map(({ label, link, tagBefore }) => ({
                label,
                link,
                key: link,
                iconAfter: (
                    <ChevronRight variables={{ '1xSize': pxToRem(12) }} />
                ),

                ...(tagBefore && {
                    customChildComponent: (
                        <Box
                            // TS2590: Expression produces a union type that is too complex to represent.
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            className="tagBefore"
                            {...renderAnchorTag(link + '?forSale=1')}
                            onClick={e => {
                                // Prevent mobile nav opening
                                e.stopPropagation();
                            }}
                        >
                            {tagBefore}
                        </Box>
                    ),
                    styles: {
                        [`& > *`]: {
                            marginTop: pxToRem(-5),
                            order: 2,
                        },
                        ['& .tagBefore']: {
                            order: 1,
                            marginRight: pxToRem(8),
                            padding: pxArrayToRem([0, 15]),
                        },
                    },
                }),
            })),
            onItemClick: (_, data) => {
                if (!data?.link) return;
                onCategoryItemClick(data.link);
            },
        },
        minorNavItems: {
            items: MINOR_NAV_ITEMS.map(({ link, ...rest }) => ({
                ...rest,
                ...(link && renderAnchorTag(link)),
                key: link,
            })),
        },
        eventItems: EVENTS_ITEMS.map(event => ({
            key: event.link,
            children: (
                <Flex column {...renderAnchorTag(event.link)}>
                    <Image
                        src={`${assetsBaseUrl}${event.imageSrc}`}
                        alt={event.title}
                        width={191}
                        height={155}
                        layout={'responsive'}
                    ></Image>
                    <Caption variant="04" design={{ marginTop: pxToRem(5) }}>
                        {event.title}
                    </Caption>
                </Flex>
            ),
        })),
        showSearchMenu,
        bottomNavigationAdditionalContent,
    };
}
