import {
    avatarClassName,
    buttonClassName,
    containerClassName,
    DESKTOP_TOP_NAV_HEIGHT_FULL_LOGO,
    flexClassName,
    DESKTOP_TOP_NAV_HEIGHT_SHORT_LOGO,
    MOBILE_TOP_NAV_HEIGHT_FULL_LOGO,
    MOBILE_TOP_NAV_HEIGHT_SHORT_LOGO,
    DESKTOP_TOP_NAV_HEIGHT_WITH_SECOND_NAV,
    pxToRem,
    StyleRule,
    TABLET_TOP_NAV_HEIGHT,
    topNavigationClassName,
    topNavigationSlotClassNames,
    featuredTileClassName,
    captionClassName,
    inputSlotClassNames,
    searchBarClassName,
    appMenuSlotClassNames,
    categoryTreeHeaderSlotClassNames,
    pxArrayToRem,
    projectTileClassName,
} from '@archipro-design/aria';
import { svgIconClassName } from '@archipro-design/icons';
import { TopNavTransitionState } from './types';
import { hex2rgba } from './utils/hex2rgba';

export const slideInKeyframes = () => ({
    from: { transform: 'translateY(-100%)' },
    to: { transform: 'translateY(0%)' },
});
export const slideUpKeyframes = () => ({
    from: { transform: 'translateY(0%)' },
    to: { transform: 'translateY(-100%)' },
});

export const MOBILE_TOP_NAV_FULL_LOGO_HEIGHT = pxToRem(
    MOBILE_TOP_NAV_HEIGHT_FULL_LOGO
);
export const MOBILE_TOP_NAV_LARGE_LOGO_HEIGHT = pxToRem(
    MOBILE_TOP_NAV_HEIGHT_SHORT_LOGO
);
// 78 is search input bar height + search input bar margin top, will get these from aria too
export const MOBILE_TOP_NAV_LARGE_LOGO_WITH_INPUT_HEIGHT = pxToRem(
    MOBILE_TOP_NAV_HEIGHT_SHORT_LOGO + 78
);
export const DESKTOP_TOP_NAV_FULL_LOGO_HEIGHT = pxToRem(
    DESKTOP_TOP_NAV_HEIGHT_FULL_LOGO
);
export const DESKTOP_TOP_NAV_LARGE_LOGO_HEIGHT = pxToRem(
    DESKTOP_TOP_NAV_HEIGHT_SHORT_LOGO
);

export const TABLET_TOP_NAV_FULL_LOGO_HEIGHT = pxToRem(TABLET_TOP_NAV_HEIGHT);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const TopNavigationContainer: StyleRule<{
    categoryTreeActive: boolean;
    topNavState: TopNavTransitionState;
    showBanner: boolean;
    minimalTopNav: boolean;
    alwaysFloating?: boolean;
    transparent?: boolean;
}> = ({
    theme,
    categoryTreeActive,
    topNavState,
    showBanner,
    minimalTopNav,
    alwaysFloating,
    transparent = false,
}) => {
    return {
        ...(categoryTreeActive && {
            [`& .${topNavigationClassName}`]: {
                width: '-webkit-fill-available',
                height: '-webkit-fill-available',
            },
        }),

        ...(!transparent && {
            [`& > .${topNavigationClassName}`]: {
                borderBottom: `1px solid ${hex2rgba(
                    theme.siteVariables.colors.primitive.black,
                    0.1
                )}`,
            },
        }),

        [`& .${buttonClassName} .${svgIconClassName}`]: {
            '&:hover': {
                '& svg': {
                    color: 'inherit',
                    fill: 'currentColor',
                },
            },
        },

        [`& .${topNavigationSlotClassNames.menuButton} .${svgIconClassName}`]: {
            '& svg': {
                color: 'inherit',
                fill: 'currentColor',
            },
        },

        [`& .${appMenuSlotClassNames.screen}`]: {
            [`& .tagBefore`]: {
                background: theme.siteVariables.colors.danger['300'],
                color: theme.siteVariables.colors.primitive.white,
                width: pxToRem(60),
                height: pxToRem(38),
                borderRadius: pxToRem(100),
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginLeft: pxToRem(-10),
            },

            [`& .${appMenuSlotClassNames.events}`]: {
                [`& > .${captionClassName}`]: {
                    letterSpacing: '0.2em',
                },
            },

            [`& .${categoryTreeHeaderSlotClassNames.contentContainer}`]: {
                [`& .${categoryTreeHeaderSlotClassNames.title}`]: {
                    ...theme.siteVariables.textStyles.Heading.Medium65,
                    textTransform: 'uppercase',
                },
            },
        },

        [theme.screen.laptop]: {
            height: 'auto',
            [`& > .${topNavigationClassName}`]: {
                [`& .${topNavigationSlotClassNames.secondaryNavContainer}`]: {
                    marginLeft: pxToRem(-10),
                },
            },
            ...(minimalTopNav && {
                [`& .${topNavigationClassName}`]: {
                    background: 'transparent',
                    position: 'relative',
                },
                [`& .${topNavigationClassName} .${containerClassName} div`]: {
                    gridTemplateColumns: '1fr',
                    display: 'flex',
                    flexWrap: 'wrap-reverse',
                },
            }),

            [`& > .${svgIconClassName}`]: {
                paddingRight: 0,
                marginTop: pxToRem(4),
            },

            [`& .${topNavigationSlotClassNames.rightButton}`]: {
                paddingRight: 0,
                paddingLeft: 0,
                [`&:hover`]: {
                    textDecoration: 'none',
                },
            },
            [`& .${flexClassName}`]: {
                [`& .${buttonClassName}`]: {
                    borderWidth: pxToRem(1),
                },

                [`& .${buttonClassName}:last-of-type`]: {
                    borderRight: 'none',
                },
                [`& .${buttonClassName}:last-of-type:not(:last-child)`]: {
                    ':hover': {
                        backgroundColor: 'transparent',
                    },
                },
            },
        },

        '& > .productBanner': {
            display: showBanner ? 'none' : 'auto',
            position:
                topNavState !== 'static' || alwaysFloating
                    ? 'fixed'
                    : 'absolute',
            top: MOBILE_TOP_NAV_FULL_LOGO_HEIGHT,

            [theme.screen.tablet]: {
                top: pxToRem(DESKTOP_TOP_NAV_HEIGHT_WITH_SECOND_NAV),
            },
        },
        [`& .${featuredTileClassName} > .${captionClassName}`]: {
            fontSize: pxToRem(16),
            minHeight: 'unset',
            paddingLeft: pxToRem(9),
        },
        [`& .${featuredTileClassName} > .${projectTileClassName}`]: {
            paddingLeft: pxToRem(9),
            paddingTop: pxToRem(9),
        },
        [`& .${topNavigationSlotClassNames.searchBar} .${searchBarClassName} input.${inputSlotClassNames.input}`]:
            {
                [`&::placeholder`]: {
                    color: theme.siteVariables.colorScheme.default
                        ?.foregroundDisabled,
                },
            },
    };
};

export const disableTopNavigationAnimation: StyleRule = () => {
    return {
        position: 'absolute',
    };
};

export const enableTopNavigationAnimation: StyleRule<{
    topNavState: TopNavTransitionState;
    slideInKeyframes: string;
    slideUpKeyframes: string;
    backgroundColorOnFloat?: string;
    isMegamenuActive?: boolean;
    alwaysFloating?: boolean;
}> = ({
    topNavState,
    slideUpKeyframes,
    slideInKeyframes,
    backgroundColorOnFloat,
    isMegamenuActive,
    alwaysFloating,
}) => {
    let forceBackgroundColour: string | undefined;
    //activate backgroundColorOnFloat only when megaMenu is collapsed and topnav is floating
    if (
        backgroundColorOnFloat &&
        !isMegamenuActive &&
        topNavState !== 'static'
    ) {
        forceBackgroundColour = backgroundColorOnFloat;
    }

    return {
        ...(topNavState !== 'static' &&
            !alwaysFloating && {
                animation: `${
                    topNavState === 'slide-in'
                        ? slideInKeyframes
                        : slideUpKeyframes
                } 150ms 0s ease normal forwards`,
            }),
        ...(forceBackgroundColour && {
            [`&.${topNavigationClassName}`]: {
                background: forceBackgroundColour,
            },
        }),
    };
};

export const TopNavigationSpacer: StyleRule<{
    slideInKeyframes: string;
    slideUpKeyframes: string;
    showFullLogo: boolean;
    showSearchBar: boolean;
    isDesktop: boolean;
    showSubNavigation: boolean;
}> = ({ showFullLogo, showSearchBar, theme, isDesktop, showSubNavigation }) => {
    const desktopStyle = {
        height: showSubNavigation
            ? pxToRem(DESKTOP_TOP_NAV_HEIGHT_WITH_SECOND_NAV)
            : DESKTOP_TOP_NAV_FULL_LOGO_HEIGHT,
    };
    return {
        backgroundColor: theme.siteVariables.bodyBackground,
        height: showFullLogo
            ? MOBILE_TOP_NAV_FULL_LOGO_HEIGHT
            : showSearchBar
            ? MOBILE_TOP_NAV_LARGE_LOGO_WITH_INPUT_HEIGHT
            : MOBILE_TOP_NAV_LARGE_LOGO_HEIGHT,
        [theme.screen.tablet]: {
            height: TABLET_TOP_NAV_FULL_LOGO_HEIGHT,
        },
        ...(isDesktop && {
            [theme.screen.laptop]: desktopStyle,
            [theme.screen.max.shrankWindow]: desktopStyle,
        }),
    };
};

export const AvatarSection: StyleRule = () => ({
    display: 'inline-flex',
    [`& .${avatarClassName}`]: {
        top: pxToRem(-2),
        position: 'relative',
    },
    [`& .${svgIconClassName}`]: {
        position: 'relative',
        height: 'auto',
        alignSelf: 'center',
        width: pxToRem(26),
        marginLeft: pxToRem(5),
        marginRight: pxToRem(19),
    },
});

export const AOCSectionContainer: StyleRule<{
    forceBackground?: string;
}> = ({ forceBackground }) => ({
    ...(forceBackground && {
        backgroundColor: forceBackground,
    }),
});

export const AOCSection: StyleRule<{
    onDarkPage: boolean;
}> = ({ theme, onDarkPage }) => ({
    padding: pxArrayToRem([60, 50]),
    maxWidth: pxToRem(1920),

    [`& .${captionClassName}`]: {
        display: 'block',
        maxWidth: pxToRem(700),
        lineHeight: theme.siteVariables.lineHeightSmaller,
        letterSpacing: theme.siteVariables.letterSpacingSmall,
        color: onDarkPage
            ? theme.siteVariables.colors.white['100A']
            : '#0D0E12',
    },
    [theme.screen.clamped]: {
        paddingLeft: `max(0px, calc(${pxToRem(1920 + 50)} - 100vw))`,
        margin: 'auto',
    },
});

export const GuestFooterText: StyleRule = ({ theme }) => ({
    position: 'absolute',
    maxWidth: pxToRem(425),
    marginTop: pxToRem(94),
    color: theme.siteVariables.colors.primitive.white,
    lineHeight: '120%',
    textTransform: 'uppercase',
});
