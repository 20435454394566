import { useMemo, useCallback, useEffect, useState } from 'react';
import { useLatest } from 'ahooks';
import useImpression from './useImpression';
import { useTracker } from '../context';
import { AdTrackingEvent } from '../../types';
import { AdvertData, fireAdEvent } from '../../utils/adEventHelpers';
import { Logger } from '@archipro-website/logger';

export interface UseAdEventHandlerOut {
    onClick: AdEventHandlerOnClick;
}

export type AdEventHandlerOnClick = (
    event: AdTrackingEvent,
    followUrl?: string
) => void;

interface AdEventHandlerOptions {
    impressByVersion?: boolean;
    version?: number | string | null;
    logger?: Logger;
    disableServeEvent?: boolean;
    disableImpressionEvent?: boolean;
}

export const useAdEventHandler = (
    advertCreative: AdvertData | null,
    ref: React.MutableRefObject<Element | null>,
    opts?: AdEventHandlerOptions
): UseAdEventHandlerOut => {
    const ad = useLatest(advertCreative);
    const tracking = useTracker();
    const {
        impressByVersion,
        version,
        logger,
        disableServeEvent = false,
        disableImpressionEvent = false,
    } = opts || {};

    let pageHref = '/';
    if (typeof window !== 'undefined') {
        pageHref = window.location.href;
    }

    const { reset: resetImpression } = useImpression({
        ref,
        disable: !ad.current || opts?.disableImpressionEvent,
        impressByVersion,
        version,
        // eslint-disable-next-line @typescript-eslint/require-await
        onImpression: async (url: URL) => {
            const urlString = url.toString();

            if (!ad.current || !ref.current || disableImpressionEvent) {
                return;
            }

            // Useful for debugging
            // console.log('Ad Impression Fired', urlString, ad.current, ref.current);

            fireAdEvent(
                'Ad_Impression',
                urlString,
                ad.current,
                tracking,
                logger
            );
        },
    });

    // Fire once for each instance of the hook
    const [hasServed, setHasServed] = useState(false);

    useEffect(() => {
        if (ad.current && !disableServeEvent && !hasServed) {
            fireAdEvent('Ad_Serve', pageHref, ad.current, tracking, logger);
            setHasServed(true);
        }
    }, [ad, pageHref, tracking, logger, disableServeEvent, hasServed]);

    useEffect(() => {
        resetImpression();
    }, [pageHref, resetImpression]);

    const onClick = useCallback(
        (event: AdTrackingEvent, followUrl: string | undefined) => {
            ad.current &&
                fireAdEvent(event, pageHref, ad.current, tracking, logger);

            if (followUrl) {
                window.location.href = followUrl;
            }
        },
        [ad, pageHref, tracking, logger]
    );

    return useMemo(
        () => ({
            onClick,
        }),
        [onClick]
    );
};
