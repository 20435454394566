import { AdTrackingEvent, Tracker } from '../types';
import { AdvertCreative } from '../react-bindings/types';
import { AD_EVENTS_TO_MERGE } from './apTrackerHelpers';
import { Logger } from '@archipro-website/logger';

export type AdvertData = Pick<AdvertCreative, 'id'> & {
    professional: Pick<AdvertCreative['professional'], 'id' | 'categories'>;
};

export const fireAdEvent = (
    event: AdTrackingEvent,
    url: string,
    advertCreative: AdvertData,
    tracker: Tracker,
    logger?: Logger
) => {
    try {
        const adUrl = new URL(window.location.href);
        void tracker.log(event, {
            url: adUrl,
            label: advertCreative.id.toString(),
            targetTracker: ['archiproTracker', 'ga4Tracker'],
            data: {
                ExtraData: composeExtraData(
                    event,
                    advertCreative.id,
                    advertCreative.professional.id
                ),
            },
        });
    } catch (e) {
        logger?.error('Failed to fire ad event', e as Error);
    }
};

const composeExtraData = (
    event: string,
    adId: number,
    professionalId: number
) => {
    if (AD_EVENTS_TO_MERGE.includes(event)) {
        // Ad_Impression, Ad_Serve
        // format: {professionalId: 1, adIds: [1,2,3]}
        return {
            adIds: [adId],
            professionalId: professionalId,
        };
    }
    // other ad events
    // format: {professionalId: 1, adId: 1}
    return JSON.stringify({
        adId: adId,
        professionalId: professionalId,
    });
};
