import {
    pxToRem,
    StyleRule,
    captionClassName,
    imageClassName,
} from '@archipro-design/aria';

export const ReseneSponsor: StyleRule = () => ({
    marginTop: pxToRem(28),
    marginLeft: pxToRem(24),
    marginBottom: pxToRem(32),
});

export const ReseneSponsorHeader: StyleRule = () => ({
    marginBottom: pxToRem(8),
    [`&.${captionClassName}`]: {
        fontSize: pxToRem(16),
        lineHeight: pxToRem(17.6),
    },
});

export const ReseneSponsorLogo: StyleRule = () => ({
    width: pxToRem(140),
    height: pxToRem(54),

    [`& .${imageClassName}`]: {
        width: '100%',
    },
});
