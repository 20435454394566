import { Flex, useStyles, Caption, Image } from '@archipro-design/aria';
import { RenderAnchorTag } from '../../types';
import * as S from './MegaMenuReseneSponsor.style';
import { SyntheticEvent, forwardRef } from 'react';

interface MegaMenuReseneSponsorProps {
    assetsBaseUrl: string;
    renderAnchorTag: RenderAnchorTag;
    onClick?: (e: SyntheticEvent) => void;
}

export const MegaMenuReseneSponsor = forwardRef<
    HTMLDivElement,
    MegaMenuReseneSponsorProps
>((opt, ref) => {
    const { renderAnchorTag, onClick, assetsBaseUrl } = opt;
    const { css } = useStyles();

    return (
        <Flex column className={css(S.ReseneSponsor)}>
            <Caption
                variant={'01'}
                weight="medium"
                className={css(S.ReseneSponsorHeader)}
            >{`Sponsored by`}</Caption>
            <Flex
                ref={ref}
                className={css(S.ReseneSponsorLogo)}
                {...renderAnchorTag('/professional/resene')}
                onClick={onClick}
            >
                <Image
                    src={`${assetsBaseUrl}/assets/website/images/ui/megamenu_resene_logo_187.png`}
                    width={187}
                    height={74}
                    layout="responsive"
                    alt="Sponsored by Resene"
                    variables={{
                        placeholderBackgroundColorLight: '#faf9f7',
                    }}
                />
            </Flex>
        </Flex>
    );
});
