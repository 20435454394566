import React from 'react';
import {
    createElement,
    ElementType,
    FunctionComponent,
    RefAttributes,
} from 'react';
import type {
    SubscriptionProps as AriaSubscriptionProps,
    ButtonSizes,
    InputProps,
} from '@archipro-design/aria';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { CustomArrowRight } from '@archipro-design/icons';

const subscriptionSchema = z.object({
    register_newsletter_user_email: z
        .string()
        .min(5, { message: 'Email address is required' })
        .email('This is not a valid email address'),
});

type SubscriptionAttributes = z.infer<typeof subscriptionSchema>;

export interface SubscriptionProps {
    Component: ElementType<AriaSubscriptionProps>;
    componentProps: AriaSubscriptionProps;
    /**
     * Used to inject container.
     * Remix uses fetcher.Form, manager uses just a regular 'form' element
     */
    formElement?: 'form' | FunctionComponent<RefAttributes<HTMLFormElement>>;

    onSubmit: (email: string) => void;
    error?: string;
    success?: boolean;
}

export const Subscription = (props: SubscriptionProps) => {
    const {
        Component,
        componentProps,
        error,
        success,
        formElement = 'form',
        onSubmit: onSubmitProp,
    } = props;

    const {
        handleSubmit,
        register,
        formState: { errors },
    } = useForm<SubscriptionAttributes>({
        resolver: zodResolver(subscriptionSchema),
        mode: 'onSubmit',
    });

    const { onChange, name, ref } = register('register_newsletter_user_email');
    const onSubmit: SubmitHandler<{
        register_newsletter_user_email: string;
    }> = data => {
        const { register_newsletter_user_email: email } = data;
        if (email && !errors?.register_newsletter_user_email?.message) {
            onSubmitProp(email.toLowerCase());
        }
    };

    return createElement(
        formElement as 'form',
        {
            style: {
                width: '100%',
            },
            onSubmit: handleSubmit(onSubmit),
        },

        <Component
            {...componentProps}
            submit={{
                type: 'submit',
                icon: (
                    <CustomArrowRight
                        styles={{ '& svg path': { fill: 'white' } }}
                    />
                ),
                children: undefined,
                size: 24 as ButtonSizes,
                variables: {
                    iconPadding: 0,
                    buttonPadding: 0,
                },
            }}
            input={{
                ...(componentProps.input as InputProps),
                id: 'footer-subscription-email',
                name: name,
                inputRef: ref,
                successIndicator: {},
                onBlur: e => {
                    e.target.value = e.target.value.toLowerCase();
                },
                onChange: e => {
                    return void onChange(e);
                },
                type: 'email',
                autoCapitalize: 'none',
            }}
            error={errors?.register_newsletter_user_email?.message ?? error}
            success={
                !errors?.register_newsletter_user_email?.message &&
                success &&
                'You are now subscribed to our newsletter!'
            }
        />
    );
};
